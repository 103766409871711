import React, { useEffect, useState } from 'react';
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Row, Col } from "reactstrap";
import { old_firestore } from '../../Firebase/firebaseConfig';
import { collection, getDocs, deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import DataTable from 'react-data-table-component';
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Baneos = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [modalData, setModalData] = useState({ id: '', date: '', note: '' });
    const [deleteId, setDeleteId] = useState(null);
    const [deleteData, setDeleteData] = useState({ id: '', note: '' });
    const [searchText, setSearchText] = useState('');
    const [filteredRows, setFilteredRows] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const toggleModal = () => setModal(!modal);
    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const getData = async () => {
        setLoading(true);
        try {
            const banRef = collection(old_firestore, 'baneo');
            const querySnapshot = await getDocs(banRef);
            const data = [];
            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                data.push({
                    id: doc.id,
                    date: docData.date ? docData.date.toDate().toLocaleDateString() : 'No disponible',
                    note: docData.note || 'Sin descripción'
                });
            });
            setRows(data);
            setFilteredRows(data);
        } catch (error) {
            console.error("Error al obtener documentos: ", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        document.title = "Banneos | TicketControl - Admin";
        getData();
    }, []);

    useEffect(() => {
        const filteredData = rows.filter(row =>
            row.id.toLowerCase().includes(searchText.toLowerCase()) ||
            row.note.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredRows(filteredData);
    }, [searchText, rows]);

    const handleDelete = async () => {
        try {
            await deleteDoc(doc(old_firestore, 'baneo', deleteId));
            setRows(rows.filter(row => row.id !== deleteId));
            toggleDeleteModal();
        } catch (error) {
            console.error("Error al eliminar el documento: ", error);
        }
    };

    const handleSave = async () => {
        try {
            const docRef = doc(old_firestore, 'baneo', modalData.id);
            if (isEdit) {
                await updateDoc(docRef, {
                    date: new Date(modalData.date),
                    note: modalData.note
                });
            } else {
                await setDoc(docRef, {
                    date: new Date(modalData.date),
                    note: modalData.note
                });
            }
            getData();
            toggleModal();
        } catch (error) {
            console.error("Error al guardar el documento: ", error);
        }
    };

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Fecha de Creación',
            selector: row => row.date,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Descripción',
            selector: row => row.note,
            sortable: true,
            grow: 3
        },
        {
            name: 'Acciones',
            cell: row => (
                <div>
                    <Button color="warning" size="sm" className="mx-2" onClick={() => editRow(row)}>Editar</Button>{' '}
                    <Button color="danger" size="sm" onClick={() => { setDeleteId(row.id); setDeleteData({ id: row.id, note: row.note }); toggleDeleteModal(); }}>Eliminar</Button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '200px'
        }
    ];

    const editRow = (row) => {
        setIsEdit(true);
        setModalData({ id: row.id, date: row.date, note: row.note });
        toggleModal();
    };

    const addRow = () => {
        setIsEdit(false);
        setModalData({ id: '', date: new Date().toISOString().split('T')[0], note: '' });
        toggleModal();
    };

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Admin" breadcrumbItem="Banneos" />
                    <Row className="mb-4">
                        <Col md={8}>
                            <FormGroup>
                                <Label for="search">Buscar</Label>
                                <Input
                                    id="search"
                                    name="search"
                                    placeholder="Buscar por ID o Descripción"
                                    type="text"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4} className="d-flex align-items-end justify-content-end">
                            <Button color="primary" onClick={addRow}>Agregar</Button>
                        </Col>
                    </Row>
                    {loading ? <p>Cargando...</p> :
                        <DataTable
                            columns={columns}
                            data={filteredRows}
                            pagination
                        />
                    }
                </Container>
            </div>

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>{isEdit ? 'Editar Documento' : 'Agregar Documento'}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="id">ID</Label>
                        <Input
                            id="id"
                            name="id"
                            placeholder="ID del documento"
                            type="text"
                            value={modalData.id}
                            onChange={(e) => setModalData({ ...modalData, id: e.target.value })}
                            disabled={isEdit}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="date">Fecha de Creación</Label>
                        <Input
                            id="date"
                            name="date"
                            placeholder="Fecha de Creación"
                            type="date"
                            value={modalData.date}
                            onChange={(e) => setModalData({ ...modalData, date: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="note">Descripción</Label>
                        <Input
                            id="note"
                            name="note"
                            placeholder="Descripción"
                            type="text"
                            value={modalData.note}
                            onChange={(e) => setModalData({ ...modalData, note: e.target.value })}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSave}>{isEdit ? 'Guardar Cambios' : 'Agregar'}</Button>{' '}
                    <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Confirmar Eliminación</ModalHeader>
                <ModalBody>
                    <p>¿Estás seguro de que deseas eliminar este registro? Esta acción no se puede deshacer.</p>
                    <p><strong>ID:</strong> {deleteData.id}</p>
                    <p><strong>Descripción:</strong> {deleteData.note}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDelete}>Eliminar</Button>{' '}
                    <Button color="secondary" onClick={toggleDeleteModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default Baneos;
