module.exports = {
  google: {
    API_KEY: "AIzaSyBP70-y5Q55MU-zBvWXP_ANO6h32W6mDYI",
    CLIENT_ID: "345180760148-j9acocj1vb6hbmvardn8c3i0tl1ol57g.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "",
  },
}


// {"web":{"client_id":"834867771135-6eacr5bqjv8nci0bnetflvtsb4rtqjbh.apps.googleusercontent.com","project_id":"ticketcontrol-22094","auth_uri":"https://accounts.google.com/o/oauth2/auth","token_uri":"https://oauth2.googleapis.com/token","auth_provider_x509_cert_url":"https://www.googleapis.com/oauth2/v1/certs","client_secret":"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","redirect_uris":["https://ticketcontrol-22094.firebaseapp.com/__/auth/handler"],"javascript_origins":["http://localhost","http://localhost:5000","https://ticketcontrol-22094.firebaseapp.com","https://monitor.ticketcontrol.com.ar"]}}