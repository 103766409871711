import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";

const Filtros = ({ onFilterChange, customers }) => {

    const [advance, setAdvance] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [lastConnectionFrom, setLastConnectionFrom] = useState('');
    const [lastConnectionTo, setLastConnectionTo] = useState('');
    const [licenceUntilFrom, setLicenceUntilFrom] = useState('');
    const [licenceUntilTo, setLicenceUntilTo] = useState('');
    const [versionFrom, setVersionFrom] = useState('');
    const [versionTo, setVersionTo] = useState('');
    const [porvencer, setPorVencer] = useState(false);
    const [customerType, setCustomerType] = useState('Todos');

    const compareVersions = (a, b) => {
        const pa = a.split('.').map(Number);
        const pb = b.split('.').map(Number);

        for (let i = 0; i < Math.max(pa.length, pb.length); i++) {
            if ((pa[i] || 0) > (pb[i] || 0)) return -1;
            if ((pa[i] || 0) < (pb[i] || 0)) return 1;
        }

        return 0;
    };


    const versions = [...new Set(customers.map(customer => customer.app_config?.version_db))]
        .filter(Boolean)
        .sort(compareVersions);

    const handleSelectChange = (e) => {
        setCustomerType(e.target.value);
    };
    
    const handleFilterChange = () => {
        onFilterChange({
            searchText,
            lastConnectionFrom,
            lastConnectionTo,
            licenceUntilFrom,
            licenceUntilTo,
            versionFrom,
            versionTo,
            porvencer,
            customerType,
        });
    };

    useEffect(() => {
        handleFilterChange();
    }, [searchText, lastConnectionFrom, lastConnectionTo, licenceUntilFrom, licenceUntilTo, versionFrom, versionTo, porvencer, customerType]);

    useEffect(() => {
        setAdvance(false);
    }, []);

    return (
        <Form>
            <Row className="align-items-center">
                <Col md={false}>
                    <FormGroup floating >
                        <Input
                            id="search"
                            name="search"
                            placeholder="Buscar"
                            type="text"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />

                        <Label for="search">Buscar</Label>

                    </FormGroup>
                </Col>
                <Col md={'auto'}>
                    {/* Checkbox */}
                    <FormGroup check>
                            <Input type="checkbox" checked={porvencer} id='porvencer'
                                onChange={(e) => setPorVencer(e.target.checked)}
                        /> <Label check for='porvencer'>
                            Por vencer en 30 días
                        </Label>
                    </FormGroup>
                </Col>
                <Col md={'auto'}>
                    <FormGroup floating>
                        <Input
                            id="exampleSelect"
                            name="select"
                            type="select"
                            style={{ minWidth: '200px' }}
                            onChange={handleSelectChange}
                        >
                            <option>
                                Todos
                            </option>
                            <option>
                                PRO
                            </option>
                            <option>
                                Free
                            </option>
                           
                        </Input>
                        <Label for="exampleSelect">
                            Tipo de Cliente
                        </Label>
                    </FormGroup>
                </Col>
                
                
                <Col md={'auto'}>
                    {/* Checkbox */}
                    <Button size={'md'} color={'link'} onClick={() => { setAdvance(!advance) }}>{advance ? 'Ocultar Filtros Avanzados' : 'Mostrar Filtros Avanzados'}</Button>
                </Col>
            </Row>
            {advance && <Row>
                <Col md={4}>
                    <Row>
                        <Col md={12}>
                            <FormGroup floating>
                                <Input
                                    id="lastConnectionFrom"
                                    name="lastConnectionFrom"
                                    type="date"
                                    value={lastConnectionFrom}
                                    placeholder="Última Conexión Desde"
                                    onChange={(e) => setLastConnectionFrom(e.target.value)}
                                />

                                <Label for="lastConnectionFrom">Última Conexión Desde</Label>

                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup floating>
                                <Input
                                    id="lastConnectionTo"
                                    name="lastConnectionTo"
                                    type="date"
                                    value={lastConnectionTo}
                                    placeholder="Última Conexión Hasta"
                                    onChange={(e) => setLastConnectionTo(e.target.value)}
                                />
                                <Label for="lastConnectionTo">Última Conexión Hasta</Label>

                            </FormGroup>
                        </Col>
                    </Row>
                </Col>

                <Col md={4}>
                    <Row>
                        <Col md={12}>
                            <FormGroup floating>
                                <Input
                                    id="licenceUntilFrom"
                                    name="licenceUntilFrom"
                                    type="date"
                                    value={licenceUntilFrom}
                                    placeholder="Vencimiento Licencia Desde"
                                    onChange={(e) => setLicenceUntilFrom(e.target.value)}
                                />
                                <Label for="licenceUntilFrom">Vencimiento Licencia Desde</Label>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup floating>
                                <Input
                                    id="licenceUntilTo"
                                    name="licenceUntilTo"
                                    type="date"
                                    value={licenceUntilTo}
                                    placeholder="Vencimiento Licencia Hasta"
                                    onChange={(e) => setLicenceUntilTo(e.target.value)}
                                />
                                <Label for="licenceUntilTo">Vencimiento Licencia Hasta</Label>
                            </FormGroup>
                        </Col>
                    </Row>

                </Col>
                <Col md={4}>
                    <Row>
                        <Col md={12}>
                            <FormGroup floating>
                                <Input
                                    id="versionFrom"
                                    name="versionFrom"
                                    type="select"
                                    value={versionFrom}
                                    onChange={(e) => setVersionFrom(e.target.value)}
                                    placeholder="Versión Desde"
                                >
                                    <option value="">Seleccione</option>
                                    {versions.map(version => (
                                        <option key={version} value={version}>{version}</option>
                                    ))}
                                </Input>
                                <Label for="versionFrom">Versión Desde</Label>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup floating>
                                <Input
                                    id="versionTo"
                                    name="versionTo"
                                    type="select"
                                    placeholder="Versión Hasta"
                                    value={versionTo}
                                    onChange={(e) => setVersionTo(e.target.value)}
                                >
                                    <option value="">Seleccione</option>
                                    {versions.map(version => (
                                        <option key={version} value={version}>{version}</option>
                                    ))}
                                </Input>
                                <Label for="versionTo">Versión Hasta</Label>

                            </FormGroup>
                        </Col>
                    </Row>
                </Col>


            </Row>}
        </Form >
    );
};

export default Filtros;
