import { FETCH_DATA_COMPANIES, SET_DATA_COMPANIES, SET_CURRENT_COMPANY, SET_CURRENT_COMPANY_BY_ID,ADD_COMPANY} from "./actionTypes"

export const fetchDataCompanies = () => ({
  type: FETCH_DATA_COMPANIES,
})

export const setDataCompanies = (data) => {
  return {
    type: SET_DATA_COMPANIES,
    payload: data
  };
};


export const setCurrentCompany = (data) => {
  console.log('guardando company', data)
  return {
    type: SET_CURRENT_COMPANY,
    payload: data
  }
}


export const setCurrentCompanyById = (id) => ({
  type: SET_CURRENT_COMPANY_BY_ID,
  payload: id
}) 

export const addCompany = (data) => ({
  type: ADD_COMPANY,
  payload: data
})
