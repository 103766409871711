import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
// import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { map } from 'lodash';
import numeral from 'numeral'
import moments from 'moment'
moments.locale('es')



const TableCC = (props) => {
   
    const columns = [

        {
            name: <span className='font-weight-bold fs-13'>ID</span>,
            selector: row => row.id,
            sortable: true,
            width: "90px",
            cellExport: row => row.id,
        },
        {
            name: <span className='font-weight-bold fs-13'>Cliente</span>,
            selector: row => <span style={{ fontWeight: 700 }}>{row.fullName.toUpperCase()}</span>,
            sortable: true,
            wrap: true,
            width: "250px",
            cellExport: row => row.fullName,

        },
        {
            name: <span className='font-weight-bold fs-13'>Matrícula</span>,
            selector: row => row.plate ? row.plate.toUpperCase():'No',
            sortable: true,
            wrap: true,
            cellExport: row => row.plate,
        },
        {
            name: <span className='font-weight-bold fs-13'>Categoría</span>,
            selector: row => row.category_name.toUpperCase(),
            sortable: true,
            wrap: true,
            cellExport: row => row.category_name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Marca/Modelo</span>,
            selector: row => row.brand.toUpperCase(),
            sortable: true,
            cellExport: row => row.brand,
        },
        {
            name: <span className='font-weight-bold fs-13'>Ubicación</span>,
            selector: row => row.floor_name,
            sortable: true,
            cellExport: row => row.floor_name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Tarifa</span>,
            selector: row => row.timelaspes_label.toUpperCase(),
            sortable: true,
            cellExport: row => row.timelaspes_label,
        },
        {
            name: <span className='font-weight-bold fs-13'>Balance</span>,
            selector: row => (<span><strong>{numeral(row.balance).format('$ 0,0')}</strong></span>),
            sortable: true,
            cellExport: row => row.balance,
        },
        {
            name: <span className='font-weight-bold fs-13'>Prox. Cargo</span>,
            selector: row => moments(row.next_movement).format('DD/MM/YYYY'),
            sortable: true,
            cellExport: row => row.next_movement,   
        },
        {
            name: <span className='font-weight-bold fs-13'>Estado</span>,
            selector: (cell) => {

                if (cell.balance < 0) {
                    return <span className="badge badge-soft-danger  " style={{ fontSize: '0.8em' }}> VENCIDO </span>;
                }
                else if (cell.balance >= 0) {
                    return <span className="badge badge-soft-success" style={{ fontSize: '0.8em' }}> AL DIA </span>;
                }
            },
            sortable: true,
            cellExport: row => row.balance,
        },

        // {
        //     name: <span className='font-weight-bold fs-13'>Status</span>,
        //     sortable: true,
        //     selector: (cell) => {
        //         switch (cell.status) {
        //             case "Re-open":
        //                 return <span className="badge badge-soft-info"> {cell.status} </span>;
        //             case "On-Hold":
        //                 return <span className="badge badge-soft-secondary"> {cell.status} </span>;
        //             case "Closed":
        //                 return <span className="badge badge-soft-danger"> {cell.status} </span>;
        //             case "Inprogress":
        //                 return <span className="badge badge-soft-warning"> {cell.status} </span>;
        //             case "Open":
        //                 return <span className="badge badge-soft-primary"> {cell.status} </span>;
        //             case "New":
        //                 return <span className="badge badge-soft-success"> {cell.status} </span>;
        //             default:
        //                 return <span className="badge badge-soft-success"> {cell.status} </span>;
        //         }
        //     },
        // },
        // // {
        // //     name: <span className='font-weight-bold fs-13'>Priority</span>,
        // //     sortable: true,
        // //     selector: (cell) => {
        // //         switch (cell.priority) {
        // //             case "High":
        // //                 return <span className="badge bg-danger"> {cell.priority} </span>;
        // //             case "Medium":
        // //                 return <span className="badge bg-info"> {cell.priority} </span>;
        // //             case "Low":
        // //                 return <span className="badge bg-success"> {cell.priority} </span>;
        // //             default:
        // //                 return <span className="badge bg-danger"> {cell.priority} </span>;
        // //         }
        // //     },
        // // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Action</span>,
        //     sortable: true,
        //     cell: () => {
        //         return (
        //             <UncontrolledDropdown className="dropdown d-inline-block">
        //                 <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
        //                     <i className="ri-more-fill align-middle"></i>
        //                 </DropdownToggle>
        //                 <DropdownMenu className="dropdown-menu-end">
        //                     <DropdownItem href="#!"><i className="ri-eye-fill align-bottom me-2 text-muted"></i>View</DropdownItem>
        //                     <DropdownItem className='edit-item-btn'><i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit</DropdownItem>
        //                     <DropdownItem className='remove-item-btn'> <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
        //                 </DropdownMenu>
        //             </UncontrolledDropdown>
        //         );
        //     },
        // },
    ];


    // a = { 'cc_id': 56, 'category_id': 2, 'timelapse_id': 8, 'closed': 0, 'user_id': 6, 'plate': 'DASD', 'brand': 'asdasd', 'cc_date_init': '2023-05-25 17:33:58.0000', 'cc_date_end': None, 'balance': -3387.1, 'next_movement': '2023-06-01 00:00:00', 'fullName': 'ssssssss aaaaaaa', 'phone': '', 'timelaspes_label': 'MENSUAL C/ DESC', 'timelapses_time': 43200, 'timelapse_tolerance': 0, 'category_name': 'Auto', 'rate_value': 15000.0, 'place_id': None, 'place_name': None, 'floor_name': None }


    let items = []

    
    props.cc.forEach((cc, index) => {
        const item = {
            id: cc['cc_id'],
            fullName: cc['fullName'],
            category_name: cc['category_name'],
            brand: cc['brand'],
            plate: cc['plate'],
            floor_name: cc['place_name'] ? cc['floor_name'] + " - " + cc['place_name'] : 'Sin asignar',
            timelaspes_label: cc['timelaspes_label'],
            balance: cc['balance'],
            next_movement: cc['next_movement'],
            status: cc['balance']
        }

        items.push(item)

    })

    const data = items



    const tableData = {
        columns,
        data
      };

      const customMessages = {
        // Mensajes del encabezado de la tabla
        toolbar: {
          search: 'Buscar',
          downloadCsv: 'Descargar CSV',
          print: 'Imprimir',
          viewColumns: 'Ver columnas',
          filterTable: 'Filtrar tabla',
        },
        // Otros mensajes personalizados que desees cambiar
        // ...
      };

    return (
        <div>
            {/* Input de búsqueda */}
  
    <DataTableExtensions {...tableData} exportHeaders={false} filterPlaceholder='Filtrar'>
            <DataTable
                title={'Mensuales ('+String(data.length)+')'}
                // columns={columns}
                // data={data}
                pagination
                highlightOnHover
                paginationPerPage={25}
                className="data-table"
                // noHeader={true}
                paginationDefaultPage={1}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por página:',
                    rangeSeparatorText: 'de',
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'All',
                    toolbar: {
                        search: 'Buscar',
                        downloadCsv: 'Descargar CSV',
                        print: 'Imprimir',
                        viewColumns: 'Ver columnas',
                        filterTable: 'Filtrar tabla',
                      },
                    
                }}
                customMessages={customMessages}

                // subHeader
                // subHeaderComponent={
                //     <FilterComponent
                //       onFilter={handleFilterChange}
                //       onClear={handleClear}
                //       filterText={filterText}
                //     />
                //   }
                // paginationResetDefaultPage={resetPaginationToggle}
                noDataComponent={<div className="p-4 text-center">No hay datos</div>}
                persistTableHead
            />
            </DataTableExtensions>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        cc: state.data.cc
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TableCC)