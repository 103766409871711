import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import TableCurrentCash from "./tableCurrentCash"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classnames from "classnames";

const Cash = () => {
  document.title = "CASH | Upzet - React Admin & CASH Template";

  const [activeTab2, setactiveTab2] = useState("5");

  const toggle2 = (tab) => {
    if (activeTab2 !== tab) {
      setactiveTab2(tab);
    }
  };

  useEffect(() => { 
    toggle2("1");
  }, []);




  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="TicketControl" breadcrumbItem="Caja" />
          <TableCurrentCash />

          {/* <Row>
            <Col lg={12}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab2 === "1",
                    })}
                    onClick={() => {
                      toggle2("1");
                    }}
                  >
                    <i className="dripicons-home me-1 align-middle"> </i>{" "}
                    Caja Actual
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab2 === "2",
                    })}
                    onClick={() => {
                      toggle2("2");
                    }}
                  >
                    <i className="dripicons-user me-1 align-middle"></i>{" "}
                    Cierres de Caja
                  </NavLink>
                </NavItem>


              </Nav>

              <TabContent activeTab={activeTab2} className="p-3 text-muted">
                <TabPane tabId="1">
                 
                     
                       

                        {/* <Row >
                          <Col lg={12}>
                            <ResumeCash />
                          </Col>

                        </Row> <Row className="">
                          <Col lg={12}>
                            <TableCurrentCash />
                          </Col>
                        </Row>
                     
                  
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <CardText className="mb-0">
                        <Row>
                          <ScrollHorizontal />
                        </Row>
                      </CardText>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>

            </Col>

          </Row> */}

        </Container>
      </div>
    </React.Fragment>
  );
};






export default Cash;
