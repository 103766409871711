import {
    FETCH_DATA_RESUME_CASH, SET_DATA_RESUME_CASH,SET_LOGINS, ADD_LOGINS, ADD_OPENED_TICKETS, CLOSE_TICKET, 
    UPDATE_RESUME_CASH_STATE, FETCH_OPENED_TICKETS, SET_OPENED_TICKETS, SET_FOREGROUND, 
    SET_TICKETS_STATS, FETCH_TICKETS_STATS, SET_CC, SET_LICENCE, SET_APPCONFIG, SET_VERSION_DB, SET_CC_VEHICLES,
    SET_LOCATION, SET_MANAGER_DATA
} from "./actionTypes"

const initialState = {
    customer_id: 0,
    dataResumeCash: [],
    openedTickets: [],
    ticketsStats: [],
    foreground: false,
    logins: [],
    cc:[], 
    appConfig: {},
    licence: {}, 
    location: {},
    cc_vehicles: [],
    versionDB: '0.0.0', 
    managerData: {}
}

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA_RESUME_CASH:
            return {
                ...state,
                dataResumeCash: true
            }
        case SET_DATA_RESUME_CASH:
            return {
                ...state,
                dataResumeCash: action.payload
            }

        case UPDATE_RESUME_CASH_STATE:
            return {
                ...state,
                dataResumeCash: action.payload,
            };

        case FETCH_OPENED_TICKETS:
            return {
                ...state,
                openedTickets: true
            }

        case SET_OPENED_TICKETS:
            return {
                ...state,
                openedTickets: action.payload
            }

        case ADD_OPENED_TICKETS:
            return {
                ...state,
                openedTickets: [action.payload,...state.openedTickets]
            }

        case CLOSE_TICKET:
            return {
                ...state,
                openedTickets: [...state.openedTickets.filter(ticket => ticket.id !== action.payload.id)]
            }

        
        case FETCH_TICKETS_STATS:
            return {
                ...state,
                ticketsStats: true
            }

        case SET_TICKETS_STATS:
            return {
                ...state,
                ticketsStats: action.payload
            }
        
        case SET_LOGINS:
            return {
                ...state,
                logins: action.payload
            }

        case ADD_LOGINS:
            return {
                ...state,
                logins: [ action.payload, ...state.logins]
            }



        case SET_CC:
            return {
                ...state,
                cc: action.payload
            }


        case SET_FOREGROUND:
            return {
                ...state,
                foreground: action.payload
            }

        case SET_LICENCE:
            return {
                ...state,
                licence: action.payload
            }

        case SET_APPCONFIG:
            return {
                ...state,
                appConfig: action.payload
            }
        
        
        case SET_LOCATION:
            return {
                ...state,
                location: action.payload
            }
        
        case SET_VERSION_DB:
            return {
                ...state,
                versionDB: action.payload
            }

        case SET_CC_VEHICLES:
            return {
                ...state,
                cc_vehicles: action.payload
            }
        
        case SET_MANAGER_DATA:
            return {
                ...state,
                managerData: action.payload
            }

        default:
            return state
    }
}

export default dataReducer
