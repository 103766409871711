import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { collection, doc, onSnapshot, orderBy, query, where, getDocs, getDoc, limit, startAfter, startAt, updateDoc } from "firebase/firestore";
import { firestore } from "../../Firebase/firebaseConfig";

const FormModal = (props) => {


    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState({
        name: props.company.name || '',
        address: props.company.address || '',
        city: props.company.city || '',
        state: props.company.state || '',
        country: props.company.country || '',
        phone: props.company.phone || '',
        obs: props.company.obs || '',
    });




    useEffect(() => {
        setFormData({
            name: props.company.name || '',
            address: props.company.address || '',
            city: props.company.city || '',
            state: props.company.state || '',
            country: props.company.country || '',
            phone: props.company.phone || '',
            obs: props.company.obs || ''
        });
    }, [props.company]);

    const toggle = () => setModal(!modal);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        toggle(); // Cierra el modal después de enviar el formulario
        
        if (props.company.id === undefined) {
            console.log("No hay userId")
            return
        }
        
        // Asegura que ningún campo sea undefined
        const updateData = {
            company: {
                name: formData.name || '',
                address: formData.address || '',
                city: formData.city || '',
                state: formData.state || '',
                country: formData.country || '',
                phone: formData.phone || '',
                obs: formData.obs || ''
            }
        };

        const usersRef = collection(firestore, "users");
        const userIdRef = doc(usersRef, props.company.id);

        updateDoc(userIdRef, updateData, { merge: true })
            .then(() => {
                console.log("Document successfully updated with merge!");
            })
            .catch((error) => {
                console.error("Error updating document with merge: ", error);
            });


    };



    return (
        <div>
            {/* <div>{ JSON.stringify(props.company) }</div> */}
            <Button color="primary" size='sm' mb={2} onClick={toggle}>Editar Datos</Button>
         
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Formulario</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="nombre">Nombre</Label>
                            <Input type="text" name="name" id="name" placeholder="Ingresa tu nombre" onChange={handleChange} value={formData.name} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="address">Dirección</Label>
                            <Input type="text" name="address" id="address" placeholder="Ingresa tu dirección" onChange={handleChange} value={formData.address} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="city">Ciudad</Label>
                            <Input type="text" name="city" id="city" placeholder="Ingresa tu ciudad" onChange={handleChange} value={formData.city} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="state">Estado</Label>
                            <Input type="text" name="state" id="state" placeholder="Ingresa tu estado" onChange={handleChange} value={formData.state} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="country">País</Label>
                            <Input type="text" name="country" id="country" placeholder="Ingresa tu país" onChange={handleChange} value={formData.country} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="phone">Teléfono</Label>
                            <Input type="text" name="phone" id="phone" placeholder="Ingresa tu teléfono" onChange={handleChange} value={formData.phone} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="obs">obs</Label>
                            <Input type="textarea" name="obs" id="obs" placeholder="Escribe tus observaciones" onChange={handleChange} value={formData.obs} />
                        </FormGroup>
                        <Button type="submit" color="primary">Enviar</Button>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cerrar</Button>

                </ModalFooter>
            </Modal>
        </div>
    );
};

export default FormModal;