import React, { useEffect, useState } from 'react';
import { Container, Button, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Table from "./table";
import { firestore_licenses } from "../../Firebase/firebaseConfig";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import Filtros from './filtros';
import moment from 'moment'
moment.locale('es')

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});

    const fetchData = async () => {
        setLoading(true);
        const ordersRef = collection(firestore_licenses, 'orders');
        const q = query(ordersRef, orderBy('createdAt', 'desc'), limit(100)); // Puedes ajustar el límite según sea necesario

        try {
            const snapshot = await getDocs(q);
            const _orders = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setOrders(_orders);
            setFilteredOrders(_orders);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleFilterChange = (filters) => {
        setFilters(filters);
        let filtered = [...orders];

        // Filtros según los campos en la colección de órdenes
        if (filters.status) {
            filtered = filtered.filter(order => order.status === filters.status);
        }

        if (filters.searchText) {
            filtered = filtered.filter(order => order.user.toLowerCase().includes(filters.searchText.toLowerCase()));
        }

        if (filters.dateFrom) {
            filtered = filtered.filter(order => moment(order.createdAt.toDate()).isAfter(filters.dateFrom));
        }

        if (filters.dateTo) {
            filtered = filtered.filter(order => moment(order.createdAt.toDate()).isBefore(filters.dateTo));
        }

        setFilteredOrders(filtered);
    };

    return (
        <div className="page-content">
            <Container fluid={true}>
                <Breadcrumbs title="Ordenes" breadcrumbItem="Gestión de Órdenes" />
                <Filtros onFilterChange={handleFilterChange} />
                {loading && <Spinner className="m-5" color="primary">Cargando...</Spinner>}
                {!loading && <Table orders={filteredOrders} />}
                <br />
                <div style={{ textAlign: 'right' }}>
                    <Button onClick={fetchData}>Volver a Cargar Listado</Button>
                </div>
                <br />
            </Container>
        </div>
    );
}

export default Orders;
