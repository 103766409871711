// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { getAnalytics } from "firebase/analytics"
import { getFirestore,  collection, addDoc, getDocs, getDoc, doc, onSnapshot, where, query, collectionGroup, orderBy  } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
        apiKey: "AIzaSyBP70-y5Q55MU-zBvWXP_ANO6h32W6mDYI",
        authDomain: "ticketcontroldev.firebaseapp.com",
        projectId: "ticketcontroldev",
        storageBucket: "ticketcontroldev-backups",
        messagingSenderId: "345180760148",
        appId: "1:345180760148:web:3e3ef42e4e366f990d25c1",
        measurementId: "G-ERYTT9LM0L"
      };

// Initialize Firebase
const app = getApps().find(app => app.name === 'dev') || initializeApp(firebaseConfig, 'dev');

// Obtiene la instancia de Firestore
const firestore = getFirestore(app);

const firestore_licenses = getFirestore(app, 'licenses');
// Initialize Storage
const storage = getStorage(app);


/////////////////// OLD FIRESTORE ////////////////////////

const fireabseConfig_old = {
  apiKey: "AIzaSyAI5JToBTL6Gcp0-e5F7LjzEdP9uWl4XaY",
  authDomain: "ticketcontrol-22094.firebaseapp.com",
  projectId: "ticketcontrol-22094",
  // storageBucket: "ticketcontrol-22094.appspot.com",
  messagingSenderId: "834867771135",
  appId: "1:834867771135:web:3c6ef526084e6eca927eca",
  measurementId: "G-0F1KFRPP5Z"
}


const app_old = getApps().find(app => app.name === 'old') || initializeApp(fireabseConfig_old, 'old');
const old_firestore = getFirestore(app_old);



//// Función que busca todos los documentos de la colección 'users' de Firestore que no contengan la subcolección app_config, obtiene el key del documento y busca en firebase_old el documento con ese key, luego lo copia en firebase_new 
//






   




export { firestore, firestore_licenses, old_firestore, storage };
