import React, { useEffect, useState } from 'react';
import { Container, Button, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Table from "./table";
import { firestore } from "../../Firebase/firebaseConfig";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import Filtros from './filtros';
import moment from 'moment'
moment.locale('es')

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});

  const fetchData = async () => {
    setLoading(true);
    const usersRef = collection(firestore, 'users');
    const q = query(usersRef);



    try {
      const datas = await getDocs(q);
      const _customers = [];

      await Promise.all(
        datas.docs.map(async (doc) => {
          const data = doc.data();
          data.id = doc.id;

          // OBTENEMOS LASTLOGIN
          if (!data.lastLogin) {
            const loginsRef = collection(firestore, 'users', doc.id, 'logins');
            const loginsQuery = query(loginsRef, orderBy('created', 'desc'), limit(1));

            try {
              const loginsSnapshot = await getDocs(loginsQuery);
              if (!loginsSnapshot.empty) {
                const lastLogin = loginsSnapshot.docs[0].data();
                data.lastLogin = lastLogin.created;
              }
            } catch (error) {
              console.error("Error al obtener el último login:", error);
            }
          }

          _customers.push(data);
        })
      );

      setCustomers(_customers);
      setFilteredCustomers(_customers);
      sessionStorage.setItem('companies_list', JSON.stringify(_customers));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const compareVersions = (a, b) => {
    const pa = a.split('.').map(Number);
    const pb = b.split('.').map(Number);

    for (let i = 0; i < Math.max(pa.length, pb.length); i++) {
      if ((pa[i] || 0) > (pb[i] || 0)) return 1;
      if ((pa[i] || 0) < (pb[i] || 0)) return -1;
    }

    return 0;
  };


  useEffect(() => {
    const companies_list = sessionStorage.getItem('companies_list');
    if (companies_list) {
      const parsedList = JSON.parse(companies_list);
      setCustomers(parsedList);
      setFilteredCustomers(parsedList);
    } else {
      fetchData();
    }
  }, []);

  const handleFilterChange = (filters) => {
    setFilters(filters);
    let filtered = [...customers];

    // Filtro por vencimiento en los próximos 30 días
    if (filters.porvencer) {
      filtered = filtered.filter(customer => {
        if (customer.licence?.untildate) {
          const untildate = moment(customer.licence.untildate, 'YYYY-MM-DD'); // Asegúrate de que el formato es correcto
          const diff = untildate.diff(moment(), 'days');
          return diff <= 30 && diff >= 0;
        }
        return false;
      });
    }

    // Filtro de búsqueda por texto solo en los campos mostrados en la tabla
    if (filters.searchText) {
      filtered = filtered.filter(customer => {

        return (
          (customer.licence?.key && customer.licence?.key.toString().toLowerCase().includes(filters.searchText.toLowerCase())) ||
          // (customer.customer_type && customer.customer_type.toString().toLowerCase().includes(filters.searchText.toLowerCase())) ||
          (customer.company?.name && customer.company?.name.toString().toLowerCase().includes(filters.searchText.toLowerCase())) ||
          (customer.company?.address && customer.company?.address.toString().toLowerCase().includes(filters.searchText.toLowerCase())) ||
          (customer.company?.city && customer.company?.city.toString().toLowerCase().includes(filters.searchText.toLowerCase())) ||
          (customer.company?.country && customer.company?.country.toString().toLowerCase().includes(filters.searchText.toLowerCase())) ||
          (customer.city && customer.city.toString().toLowerCase().includes(filters.searchText.toLowerCase())) ||
          (customer.app_config?.version_db && customer.app_config?.version_db.toString().toLowerCase().includes(filters.searchText.toLowerCase()))
        );
      });
    }

    // Filtro por última conexión
    if (filters.lastConnectionFrom) {
      filtered = filtered.filter(customer =>
        customer.lastLogin && new Date(customer.lastLogin) >= new Date(filters.lastConnectionFrom)
      );
    }

    if (filters.lastConnectionTo) {
      console.log('filters.lastConnectionTo', filters.lastConnectionTo)

      filtered = filtered.filter(customer =>
        customer.lastLogin && new Date(customer.lastLogin) <= new Date(filters.lastConnectionTo)
      );
    }

    // Filtro por licencia hasta
    if (filters.licenceUntilFrom) {
      filtered = filtered.filter(customer =>
        customer.licence?.untildate && new Date(customer.licence?.untildate) >= new Date(filters.licenceUntilFrom)
      );
    }

    if (filters.licenceUntilTo) {
      filtered = filtered.filter(customer =>
        customer.licence?.untildate && new Date(customer.licence?.untildate) <= new Date(filters.licenceUntilTo)
      );
    }

    // Filtro por versión
    if (filters.versionFrom) {
      filtered = filtered.filter(customer =>
        customer.app_config?.version_db && compareVersions(customer.app_config?.version_db, filters.versionFrom) >= 0
      );
    }

    if (filters.versionTo) {
      filtered = filtered.filter(customer =>
        customer.app_config?.version_db && compareVersions(customer.app_config?.version_db, filters.versionTo) <= 0
      );
    }

    if (filters.customerType) {
      console.log('filters.customerType', filters.customerType)

      if (filters.customerType != 'Todos') {

        if (filters.customerType == 'PRO') {
          filtered = filtered.filter(customer =>
            customer.managerData?.isPro == true
          );
        }
        else {
          filtered = filtered.filter(customer =>
            customer.managerData?.isPro != true
          );
        }

      
      }

    }

    console.log("Filtered Customers:", filtered);
    setFilteredCustomers(filtered);
  };

  return (
    <div className="page-content">
      <Container fluid={true}>
        {/* <div>{filteredCustomers.length}</div>
        <div>{JSON.stringify(filters)}</div> */}
        <Filtros onFilterChange={handleFilterChange} customers={customers} />
        {loading && <Spinner className="m-5" color="primary">Loading...</Spinner>}
        {!loading && <Table customers={filteredCustomers} />}
        <br />
        <div style={{ textAlign: 'right' }}>
          <Button onClick={fetchData}>Volver a Cargar Listado</Button>
        </div>
        <br />
      </Container>
    </div>
  );
}

export default Customers;
