import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";

const Filtros = ({ onFilterChange }) => {
    const [searchText, setSearchText] = useState('');
    const [status, setStatus] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    const handleFilterChange = () => {
        onFilterChange({
            searchText,
            status,
            dateFrom,
            dateTo,
        });
    };

    useEffect(() => {
        handleFilterChange();
    }, [searchText, status, dateFrom, dateTo]);

    return (
        <Form>
            <Row className="align-items-center">
                <Col md={3}>
                    <FormGroup floating>
                        <Input
                            type="text"
                            placeholder="Buscar Cliente"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <Label for="searchText">Buscar Cliente</Label>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup floating>
                        <Input
                            type="select"
                            placeholder="Estado"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <option value="">Todos</option>
                            <option value="approved">Aprobado</option>
                            <option value="pending">Pendiente</option>
                            <option value="rejected">Rechazado</option>
                        </Input>
                        <Label for="status">Estado</Label>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup floating>
                        <Input
                            type="date"
                            placeholder="Desde"
                            value={dateFrom}
                            onChange={(e) => setDateFrom(e.target.value)}
                        />
                        <Label for="dateFrom">Fecha Desde</Label>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup floating>
                        <Input
                            type="date"
                            placeholder="Hasta"
                            value={dateTo}
                            onChange={(e) => setDateTo(e.target.value)}
                        />
                        <Label for="dateTo">Fecha Hasta</Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={handleFilterChange} color="primary">Aplicar Filtros</Button>
                </Col>
            </Row>
        </Form>
    );
};

export default Filtros;
