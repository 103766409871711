import React , {useEffect, useState} from 'react';
import { Container } from "reactstrap";
import Table from "../Customers/table"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

    const Page = () => {

        const [customers, setCustomers] = useState([1,2,3]);


        document.title = "Cuentas | TicketControl - Admin";
        return (
            <>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="New Page" breadcrumbItem="New Page" />
                        
                           <Table/>

                    </Container>
                </div>
            </>
        );
    }

export default Page;