import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Container, CardTitle } from "reactstrap";
import { connect } from 'react-redux';
import JsonViewer from "./JsonViewer";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Page = (props) => {


    document.title = "Cuentas | TicketControl - Admin";
    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="JSON" breadcrumbItem="JSON" />

                    <Row>
                        <Col xl={12} lg={12} md={12} xs={12} sm={12} >
                            <JsonViewer data={props.appConfig} />
                        </Col>
                    </Row>


                </Container>
            </div>
        </>
    );
}




const mapStateToProps = (state) => {
    return {
        customer_id: state.data.customer_id,
        appConfig: state.data.appConfig,
    };
};




export default connect(mapStateToProps, null)(Page);
