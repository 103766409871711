// actionTypes.js

export const FETCH_DATA_RESUME_CASH = 'FETCH_DATA_RESUME_CASH'
export const SET_DATA_RESUME_CASH = 'SET_DATA_RESUME_CASH'
export const UPDATE_RESUME_CASH_STATE = 'UPDATE_RESUME_CASH_STATE';

export const FETCH_OPENED_TICKETS = 'FETCH_OPENED_TICKETS'
export const SET_OPENED_TICKETS = 'SET_OPENED_TICKETS'
export const ADD_OPENED_TICKETS = 'ADD_OPENED_TICKETS'
export const CLOSE_TICKET = 'CLOSE_TICKET'

export const FETCH_TICKETS_STATS = 'FETCH_TICKETS_STATS'
export const SET_TICKETS_STATS = 'SET_TICKETS_STATS'
export const SET_FOREGROUND = 'SET_FOREGROUND'

export const SET_LOGINS = 'SET_LOGINS'
export const ADD_LOGINS = 'ADD_LOGINS'

export const SET_CC = 'SET_CC'

export const SET_LICENCE = 'SET_LICENCE'
export const SET_APPCONFIG = 'SET_APPCONFIG'

export const SET_LOCATION = 'SET_LOCATION'

export const SET_CC_VEHICLES = 'SET_CC_VEHICLES'

export const SET_VERSION_DB = 'SET_VERSION_DB'

export const SET_MANAGER_DATA = 'SET_MANAGER_DATA'