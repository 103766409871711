import {
  FETCH_DATA_RESUME_CASH, SET_DATA_RESUME_CASH, FETCH_OPENED_TICKETS, SET_LOGINS,ADD_LOGINS, ADD_OPENED_TICKETS,CLOSE_TICKET,  
  SET_OPENED_TICKETS, FETCH_TICKETS_STATS, SET_TICKETS_STATS, SET_FOREGROUND, SET_CC, SET_LICENCE, SET_APPCONFIG, SET_LOCATION, SET_VERSION_DB, SET_CC_VEHICLES, SET_MANAGER_DATA
} from "./actionTypes"

export const fetchDataResumeCash = () => ({
  type: FETCH_DATA_RESUME_CASH,
})

export const setDataResumeCash = (data) => {
  return {
    type: SET_DATA_RESUME_CASH,
    payload: data
  };
};

export const setLocation = (data) => {
  return {
    type: SET_LOCATION,
    payload: data
  };
}

export const fetchOpenedTickets = () => ({
  type: FETCH_OPENED_TICKETS,
})

export const setOpenedTickets = (data) => {
  return {
    type: SET_OPENED_TICKETS,
    payload: data
  };
};

export const fetchTicketsStats = () => ({
  type: FETCH_TICKETS_STATS,
})

export const setTicketsStats = (data) => {
  return {
    type: SET_TICKETS_STATS,
    payload: data
  };
}

export const setForeground = (data) => {
  return {
    type: SET_FOREGROUND,
    payload: data
  };
}


export const setLogins = (data) => {

  return {
    type: SET_LOGINS,
    payload: data
  };
}

export const addLogins = (data) => {
  return {
    type: ADD_LOGINS,
    payload: data
  };
}

export const setCC = (data) => {
  return {
    type: SET_CC,
    payload: data
  };
}

export const addOpenedTickets = (data) => {
  return {
    type: ADD_OPENED_TICKETS,
    payload: data
  };
}

export const closeTickets = (data) => {
  return {
    type: CLOSE_TICKET,
    payload: data
  };
}

export const setLicence = (data) => {
  return {
    type: SET_LICENCE,
    payload: data
  };
}

export const setAppConfig = (data) => {
  return {
    type: SET_APPCONFIG,
    payload: data
  };
}

export const setVersionDB = (data) => {
  return {
    type: SET_VERSION_DB,
    payload: data
  };
};

export const setCCVehicles = (data) => {

  return {
    type: SET_CC_VEHICLES,
    payload: data
  };
}

export const setManagerData = (data) => {
  return {
    type: SET_MANAGER_DATA,
    payload: data
  };
}





