import React, { useEffect, useState } from 'react';
import { Container, Table, Button, Row, Col } from "reactstrap";
import { listBackups, getBackupDownloadURL } from '../../Firebase/firebaseStorage';
import { connect } from 'react-redux';



//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Función para formatear la fecha
const formatDate = (filename) => {
    const dateMatch = filename.match(/\d{4}-\d{2}-\d{2}/);
    if (dateMatch) {
        const [year, month, day] = dateMatch[0].split('-');
        return `${day}/${month}/${year}`;
    }
    return 'N/A';
};

// const formatFileName = (companyName, filename) => {
//     const formattedCompanyName = companyName.toLowerCase().replace(/ /g, '_');
//     return `${formattedCompanyName}_${filename}`;
//     // // return companyName
//     // const dateMatch = filename.match(/\d{4}-\d{2}-\d{2}/);
//     // const baseName = filename.split('_')[0]; // Obtén la parte inicial del nombre del archivo antes de la fecha
//     // if (dateMatch) {
//     //     const date = dateMatch[0];
//     //     const formattedCompanyName = companyName.toLowerCase().replace(/ /g, '_');
//     //     return `${formattedCompanyName}_${baseName}_${date}.db`;
//     // }
//     // return filename;
// };

const Backups = (props) => {

    document.title = "Cuentas | TicketControl - Admin";

    const [backups, setBackups] = useState([]);
    const [downloadURL, setDownloadURL] = useState('');


    // STORAGE 
    useEffect(() => {
        const fetchBackups = async () => {
            try {
                const backupList = await listBackups(props.company_id);
                setBackups(backupList);
            } catch (error) {
                console.error("Error fetching backups:", error);
            }
        };

        if (props.company_id) {
            fetchBackups();
        } else {
            setBackups([]);
        }
    }, [props.company_id]);

    const downloadBackup = async (fileName) => {
        try {
            const url = await getBackupDownloadURL(props.company_id, fileName);
            setDownloadURL(url);
            // Crear un elemento <a> y simular un clic para descargar el archivo con el nombre modificado
            const link = document.createElement('a');
            link.href = url;
            link.download = url
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error("Error getting download URL:", error);
        }
    };



    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Backups" breadcrumbItem="Backups" />
                   
                    <div className="table-wrapper" style={{ maxWidth: '600px', margin: '0' }}>
                        <Table responsive bordered hover className="table-centered table-nowrap">
                            <thead className="table-light">
                                <tr>
                                    <th style={{ width: 'auto' }}>Fecha</th>
                                    <th style={{ width: 'auto' }}>Nombre del Archivo</th>
                                    <th style={{ width: '1%' }}>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {backups.map((backup, index) => (
                                    <tr key={index}>
                                        <td>{formatDate(backup)}</td>
                                        <td>{backup}</td>
                                        <td className="text-center">
                                            <Button color="primary" size="sm" onClick={() => downloadBackup(backup)}>
                                                <i className="fas fa-download"></i> Descargar
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </div>
        </>
    );
}



const mapStateToProps = (state) => {
    return {
        company_id: state.companies.currentCompany?.id,
        currentCompany: state.companies.currentCompany,
        company_name: state.companies.currentCompany?.name,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
       
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Backups);
