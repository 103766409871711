import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Label } from 'reactstrap';

const ConfirmActionModal = ({ isOpen, toggle, action, actionName, confirmDelete, orderId }) => {
    const [confirmationText, setConfirmationText] = useState('');

    const handleConfirm = () => {
        if (actionName === 'Eliminar' && confirmationText !== orderId) {
            alert('Por favor, escribe correctamente el número de la orden para confirmar.');
            return;
        }
        action();
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Confirmar {actionName}</ModalHeader>
            <ModalBody>
                {actionName === 'Eliminar' ? (
                    <>
                        <p>Por favor, escribe el número de la orden <strong>{orderId}</strong> para confirmar la eliminación:</p>
                        <FormGroup>
                            <Label for="confirmationText">Número de la Orden</Label>
                            <Input
                                type="text"
                                id="confirmationText"
                                value={confirmationText}
                                onChange={(e) => setConfirmationText(e.target.value)}
                            />
                        </FormGroup>
                    </>
                ) : (
                    <p>¿Estás seguro de que deseas {actionName.toLowerCase()} esta orden?</p>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleConfirm}>Confirmar</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmActionModal;
