import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { Col, Card, CardBody, CardHeader, Row, Container, CardTitle, CardFooter, CardText } from "reactstrap";
// import { firestore } from "../../firebase";
import { connect } from 'react-redux';
import moment from 'moment';
import "moment/locale/es";

import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import "ag-grid-community/styles/ag-theme-alpine.css"; // Theme
import "./agGridCustomStyle.css"
import { LicenseManager } from 'ag-grid-enterprise';



import numeral from "numeral";
import spanishText from './agGridLocaleES';


//please asign your license key to the licenseKey constant as a string below
const licenseKey =
    'CompanyName=Equinix Asia Pacific pte ltd,LicensedGroup=equinixMendixPrivateLib,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-027567,SupportServicesEnd=18_June_2023_[v2]_MTY4NzA0MjgwMDAwMA==4be2c388f9a8a7443c72842dff53d5b2';
LicenseManager.setLicenseKey(licenseKey);
//please asign your license key to the licenseKey constant as a string above


const example = {
"articles_json": null,
"place_id": null,
"pax_phone": null,
"fly_number": null,
"fullName": "Diego TicketControl",
"canceled_details": null,
"floor_name": null,
"fly_obs": null,
"obs": null,
"init_date": "2024-02-11 12:21:14.0000",
"closed": 1,
"type": "hour",
"description": "Ticket #BG453GT - Estacionamiento por Hora",
"plate": "BG453GT",
"stay_checkout_time": null,
"stay_estimate_days": 0,
"value": 1000,
"image_out": 0,
"recognized_face": 0,
"pax_dni": null,
"category_name": "Auto",
"closed_by_user_id": 1,
"car_detail": "",
"pax_email": null,
"id": 102,
"discount": 0,
"stay_init": null,
"pax_name": null,
"deal_json": null,
"category_id": 2,
"paid": 0,
"end_date": "2024-02-11 12:21:21.0000",
"canceled": 0,
"date_landing": null,
"place_name": null,
"user_id": 1,
"image_in": 0,
"fly_company": null
}



const ClosedTickets = (props) => {


    const closedTickets = useSelector((state) => state.data.openedTickets);
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState(closedTickets);
    const GridRef = useRef()

    useEffect(() => {
        setRowData(props.closedTickets);
    }, [props.closedTickets]);

    const onGridReady = useCallback((params) => {
        console.log(params)
        setRowData(props.closedTickets);
        return
        // Asegúrate de que el grid esté listo antes de intentar llamar a setColumnState
        if (params.api && params.columnApi && params.api.getColumnDefs().length > 0) {
            const savedState = localStorage.getItem('columnState');
            if (savedState) {
                try {
                    const stateToRestore = JSON.parse(savedState);
                    params.columnApi.api.setColumnState(stateToRestore);
                } catch (e) {
                    console.error('Error al restaurar el estado de las columnas:', e);
                }
            }
        }

        // Espera hasta que el DOM esté actualizado y el grid se haya dibujado
        // setTimeout(() => params.api.sizeColumnsToFit(), 0);
        // params.api.sizeColumnsToFit();
    }, [props.closedTickets]);

    const onModelUpdated = useCallback((params) => {
        return
        if (!params.api.isAnyFilterPresent()) {
            const savedState = localStorage.getItem('columnState');
            if (savedState) {
                try {
                    const stateToRestore = JSON.parse(savedState);
                    params.columnApi.api.applyColumnState(stateToRestore);
                } catch (e) {
                    console.error('Error 222 al restaurar el estado de las columnas:', e);
                }
            }
        }
    }, []);

    // Función auxiliar para calcular la diferencia de tiempo
    function formatTimeDifference(init_date_str, end_date_str) {
        // Parsear las fechas
        const init_date = new Date(init_date_str);
        const end_date = new Date(end_date_str);
        // Calcular la diferencia de tiempo
        const diff = end_date - init_date;
        // Calcular días, horas y minutos
        const days = Math.floor(diff / (24 * 3600 * 1000));
        const hours = Math.floor((diff % (24 * 3600 * 1000)) / (3600 * 1000));
        const minutes = Math.floor((diff % (3600 * 1000)) / (60 * 1000));

        // Formatear el string de salida
        let parts = [];
        if (days) parts.push(`${days}d`);
        if (hours) parts.push(`${hours}h`);
        if (minutes) parts.push(`${minutes}min`);

        return parts.join(' ');
    }


    // Función para formatear la fecha
    function formatDate(dateString) {
        if (!dateString) return '';
        // Parsear la fecha de entrada
        const date = new Date(dateString);
        // Formatear la fecha a d/m/Y HH:mm
        const formattedDate = date.getDate().toString().padStart(2, '0') + '/'
            + (date.getMonth() + 1).toString().padStart(2, '0') + '/'
            + date.getFullYear() + ' '
            + date.getHours().toString().padStart(2, '0') + ':'
            + date.getMinutes().toString().padStart(2, '0');
        return formattedDate;
    }

    function formatPrice(price) {
        return numeral(price).format('$0,0.00');
    }


    function formatType(type) {
        switch (type) {
            case 'hour':
                return 'xHora';
            case 'stay':
                return 'xEstadia';
            case 'tn':
                return 'xTurno';
            default:
                return '--';
        }
    }



    // Column Definitions: Defines & controls grid columns.
    const [colDefs, setColDefs] = useState([
        { field: "id", sortable: true, filter: true, headerName: "ID", width: 80, minWidth: 80, maxWidth: 80 },
        { field: "plate", sortable: true, filter: true, headerName: "Matrícula", valueFormatter: (params) => "#" + params.value.toUpperCase(), width: 150, minWidth: 150, maxWidth: 200 },
        { field: "category_name", sortable: true, filter: true, headerName: "Categoria" },
        { field: "type", sortable: true, filter: true, headerName: "Tipo", valueFormatter: (params) => formatType(params.value) },
        { field: "description", sortable: true, filter: true, headerName: "Descripcion", tooltipValueGetter: (params) => params.value === null ? '' : `${params.value}` },

        { field: "init_date", sortable: true, headerName: "Fecha Inicio", valueFormatter: (params) => formatDate(params.value) },
        { field: "end_date", sortable: true, headerName: "Fecha Fin", valueFormatter: (params) => formatDate(params.value) },
        {
            headerName: "Duración",
            field: "duration",
            valueGetter: (params) => {
                if (params.data.init_date && params.data.end_date) {
                    return formatTimeDifference(params.data.init_date, params.data.end_date);
                }
                return '';
            }
        },

        { field: "value", headerName: "Valor", valueFormatter: (params) => formatPrice(params.value) },
        // { field: "paid", headerName: "Pagado", valueFormatter: (params) => formatPrice(params.value) },
        // { field: "place_name", headerName: "Ubicación" }, 
        { field: "canceled", sortable: true, headerName: "Cancelado", valueFormatter: (params) => params.value === 1 ? 'Si' : '' },
        { field: "canceled_details", sortable: true, headerName: "Motivo de cancelación", tooltipValueGetter: (params) => params.value === null ? '' : `${params.value}` },
        { field: "fullName", sortable: true, filter: true, headerName: "Abierto por" },
        { field: "closed_by_fullName", sortable: true, filter: true, headerName: "Cerrado por" },


    ]);

    



    
    // No es necesario usar useState para gridOptions ya que no se espera que cambie dinámicamente
    const gridOptions = {
        rowClassRules: {
            // Aplica la clase 'row-canceled' cuando el campo 'canceled' es igual a 1
            'row-canceled': params => params.data.canceled === '1',
        },
        defaultColDef: {
            minWidth: 150,
            filter: true,
            sortable: true,
            resizable: true,
            flex: 1,
            // editable: true,
        },
        
        sideBar: {
            toolPanels: [{
                id: 'columns',
                labelDefault: 'Columnas',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true, // Oculta la sección "Grupos"
                    suppressValues: true,    // Oculta la sección "Valores"
                    suppressPivots: true,    // Oculta la opción de pivotar
                    suppressPivotMode: true, // Oculta el interruptor de "Modo pivote"
                    // Puedes establecer otras opciones aquí si es necesario
                },
            },
                {
                    id: 'filters',
                    labelDefault: 'Filtros',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',

                },
            ],
            
        },
        localeText: spanishText, // Asegúrate de que este texto esté definido o importado correctamente
    };

    const onColumnVisible = useCallback((params) => {
        // Guardar el estado de las columnas cada vez que cambia la visibilidad
        console.log('guardamos')
        const state = params.api.getColumnState();
        localStorage.setItem('columnState', JSON.stringify(state));
    }, []);

    return (
        // Container
        <React.Fragment>
            <div className="ag-theme-alpine" style={{ height: 600, width:'100%' }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    gridOptions={gridOptions}
                    sideBar={gridOptions.sideBar}
                    onGridReady={onGridReady}
                    onModelUpdated={onModelUpdated}
                    rowClassRules={{
                        'row-canceled': params => params.data.canceled === 1,
                    }}
                    onColumnVisible={onColumnVisible}
                    ref={GridRef}
                />
            </div>
        </React.Fragment>
    )

}


const mapStateToProps = (state) => {
    return {
        closedTickets: state.data.closedTickets, // data es la propiedad en el estado de Redux donde almacenas los datos
        customer_id: state.data.customer_id,
        currentCompany: state.companies.currentCompany,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
       
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ClosedTickets);